"use client";

import { useRouter } from "next/navigation";
import { Suspense, useEffect } from "react";

export default function Custom404() {
  const router = useRouter();
  useEffect(() => {
    router.push("/");
  }, []);

  return <Suspense></Suspense>;
}
